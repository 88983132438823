import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/fonts/fonts.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from 'styled-components'; // Importuj ThemeProvider
import theme from './theme'; // Importuj swój plik motywu
import './locales/i18n';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>
);
