import React from 'react';
import { Container, Typography } from '@mui/material';
import ClientsMap from '../components/clients/ClientsMap';
import { Helmet } from 'react-helmet-async';

// function importAll(r) {
//   return r.keys().map(r);
// }

// const images = importAll(require.context('../data/clients/logos', false, /\.(png|jpe?g|svg)$/));


const Clients = () => {
  return (
    <>
    <Helmet>
      <title>Betonoki - </title>
    </Helmet>
      <Typography variant="h4" component="h1" gutterBottom>
      Doświadczenie zespołu:
      </Typography>
      <ClientsMap/>

      {/* <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
      {images.map((image, index) => (
        <img key={index} src={image} alt={`Image ${index}`} style={{ width: '100px'
       }} />
      ))}
    </div> */}
    </>
  );
};

export default Clients;