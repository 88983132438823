import React, {useEffect  } from 'react';
import { Toolbar, Box, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { menuItems } from './menuItems';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';

const Navbar = () => {
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;
  const { t, i18n } = useTranslation('navbar');

  useEffect(() => {
    const normalizedLanguage = i18n.language.split('-')[0]; 
    
    if (!normalizedLanguage || normalizedLanguage == 'pl') {
      i18n.changeLanguage('pl');
    } else {

      i18n.changeLanguage('en'); 
    }
  }, [i18n]);

  const handleLanguageToggle = () => {
    const newLang = i18n.language === 'pl' ? 'en' : 'pl';
    i18n.changeLanguage(newLang);
  };

  const logoSrc = i18n.language === 'pl' 
    ? '/img/logo_pl.svg' 
    : '/img/logo_en.svg'; 


  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#fff',
        paddingTop: 2,
        position: 'fixed',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: 2,
        }}
      >
        <Toolbar>
          <Link to="/" style={{ display: 'block', width: '100%' }}>
            <img
              // src="/img/logo.png" 
              src={logoSrc}
              alt={t('logoAlt')}
              style={{
                width: '100%', 
                maxWidth: 150, 
                height: 'auto', 
              }}
            />
          </Link>
        </Toolbar>
      </Box>

      <Box
        sx={{
          flex: 1, 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          paddingRight: 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {menuItems.map((item, index) => (
            <Button
              key={index}
              component={Link}
              to={item.path}
              fullWidth
              sx={{
                color: currentPath === item.path ? theme.palette.primary.accent : theme.palette.text.primary,
                fontWeight: 'regular',
                justifyContent: 'flex-end',
                position: 'relative',
                overflow: 'hidden',
                textAlign: 'right',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  height: '2px',
                  width: '100%',
                  backgroundColor: theme.palette.primary.accent,
                  transform: currentPath === item.path ? 'scaleX(1)' : 'scaleX(0)',
                  transformOrigin: 'bottom left',
                  transition: 'transform 0.3s ease',
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              {t(item.text)}
            </Button>
          ))}
        </Box>
      </Box>

      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 0 }}>
  <LanguageIcon sx={{ fontSize: '1.2rem', color: theme.palette.text.primary, marginRight: 1 }} />

  <Button
    onClick={() => i18n.changeLanguage('pl')}
    sx={{
      color: i18n.language === 'pl' ? theme.palette.primary.accent : theme.palette.text.primary,
      // fontWeight: i18n.language === 'pl' ? 'bold' : 'regular',
      fontWeight:'regular',
      minWidth: 'auto',
      padding: 0,
      margin: 0,
      position: 'relative',
      overflow:'visible',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0, 
        height: '2px',
        width: '100%',
        backgroundColor: theme.palette.primary.accent,
        transform: i18n.language === 'pl' ? 'scaleX(1)' : 'scaleX(0)',
        transformOrigin: 'bottom left',
        transition: 'transform 0.3s ease',
      },
    }}
  >
    PL
  </Button>

  <Box sx={{ width: '1px', height: '1rem', backgroundColor: theme.palette.divider, mx: 0.5 }} />

  <Button
    onClick={() => i18n.changeLanguage('en')}
    sx={{
      color: i18n.language === 'en' ? theme.palette.primary.accent : theme.palette.text.primary,
      // fontWeight: i18n.language === 'en' ? 'bold' : 'regular',
      fontWeight:'regular',
      minWidth: 'auto',
      padding: 0,
      margin: 0,
      position: 'relative',
      overflow: 'visible',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: '2px',
        width: '100%',
        backgroundColor: theme.palette.primary.accent,
        transform: i18n.language === 'en' ? 'scaleX(1)' : 'scaleX(0)',
        transformOrigin: 'bottom left',
        transition: 'transform 0.3s ease',
      },
    }}
  >
    ENG
  </Button>
</Box>





    </Box>
  );
};

export default Navbar;
