import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { BorderBottom } from '@mui/icons-material';



const ContactBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

const ContactText = styled(Typography)({
  fontSize: '0.8rem', 
  cursor: 'pointer',
  textAlign: 'left',
});

const ContactFooter = (props) => {
  const [showEmail, setShowEmail] = useState(false);
  const { t } = useTranslation('footer');

  const emailStart = 'kontakt';
  const emailEnd = '@betonoki.pl'; 

  const handleShowEmail = () => {
    setShowEmail(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '250px',  
          textAlign: 'left',  
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "1rem" }}>{t('contact')}</Typography>
        
        <ContactBox>
          <Typography variant='h7'>{t('companyName')}</Typography>
        </ContactBox>
        <ContactBox>
          <ContactText>{t('correspondenceAddress')}</ContactText>
        </ContactBox>

        <ContactBox>
          <PlaceOutlinedIcon sx={{ fontSize: "1.2rem", marginRight: "0.5rem" }} />
          <ContactText>ul. Fabryczna 28 <br/>42-600 Tarnowskie Góry</ContactText>
        </ContactBox>
        
        <ContactBox>
          <MailOutlineIcon sx={{ fontSize: "1.2rem", marginRight: "0.5rem" }} />
          {showEmail ? (
            <a href={`mailto:${emailStart}${emailEnd}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ContactText>{`${emailStart}${emailEnd}`}</ContactText>
            </a>
          ) : (
            <ContactText onClick={handleShowEmail}>
              {`${emailStart}@[${t('show')}]`}
            </ContactText>
          )}
        </ContactBox>
        
        <ContactBox
          component="a"
          href="https://www.linkedin.com/company/betonoki/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ borderTop: "solid 1px #555", paddingTop: 1, marginTop: 0, textDecoration: 'none', color: 'inherit' }}
        >
          <LinkedInIcon sx={{ fontSize: "1.5rem", marginRight: "0.5rem" }} />
          <ContactText>linkedin.com/company/betonoki</ContactText>
        </ContactBox>

          {/* <Box sx={{borderTop:"solid 1px #555", marginTop: "0.5rem",  padding:"0.5rem 0 0 0", color:'#fff', borderRadius:0, width:'80%'}}>
            <IconButton sx={{padding: 0, color:'#fff', borderRadius:0}} href='https://www.linkedin.com/company/betonoki/' target='blank'>
              <LinkedInIcon />
            </IconButton>
          </Box> */}
      </Box>
    </Box>
  );
};

export default ContactFooter;
