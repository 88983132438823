import React from 'react';
import { Container, Typography } from '@mui/material';
import ImageCarousel from '../components/home/ImageCarousel';
import AboutHome from '../components/home/AboutHome';
import StepsHome from '../components/home/StepsHome';
import SustainibilityHome from '../components/home/SustainibilityHome';
import TeamHome from '../components/home/TeamHome';
import BimHome from '../components/home/BimHome';
import ImageSlider from '../components/home/ImageSlider.jsx';
import HomeCarousel from '../components/home/HomeCarousel.jsx';
import HomeCarouselSimple from '../components/home/HomeCarouselSimple.jsx';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation('home');
  return (
    <>
    <Helmet>
      <title>Betonoki - {t('project studio')}</title>
    </Helmet>
      <HomeCarouselSimple/>
      {/* <AboutHome/> */}
      <StepsHome />
      <BimHome />
      <SustainibilityHome/>
      <TeamHome />  
    </>
  );
};

export default Home;
