import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import TeamCard from '../components/team/TeamCard';
// import teamData from '../data/team/team.json';
import Competences from '../components/team/Competences';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Team = () => {
  const { t } = useTranslation('team');
  const teamData = t('teamMembers', { returnObjects: true });
  
  return (
    <>
    <Helmet>
      <title>Betonoki - {t('ourTeam')}</title>
    </Helmet>
      <Typography variant="h2">
        {t('ourTeam')}
      </Typography>

      <Competences/>

      {Object.entries(teamData).map(([categoryName, members]) => (
        <div key={categoryName}>
          <Typography variant="h5" sx={{margin: "2rem 0"}}>
            {categoryName}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap="2rem" justifyContent="space-evenly">
            {members.map((member, index) => (
              <TeamCard
                key={index}
                name={member.name}
                role={member.role}
                title={member.title}
                details={member.details}
                avatar={member.avatar}
              />
            ))}
          </Box>
        </div>
      ))}
    </>
  );
};

export default Team;
