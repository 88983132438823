
import React, { useState } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { styled } from '@mui/system';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { useTranslation } from 'react-i18next';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Helmet } from 'react-helmet-async';

// Styled components for sections
const SectionTitle = ({ children }) => (
  <Typography variant="h6" sx={{ marginBottom: '0.5rem', textTransform: 'uppercase' }}>
    {children}
  </Typography>
);

const ContactBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

const ContactText = styled(Typography)({
  textAlign: 'left',
});

const Contact = () => { 
  const [showEmail, setShowEmail] = useState(false);

  const { t } = useTranslation('contact');

  const emailStart = 'kontakt';
  const emailEnd = '@betonoki.pl'; 

  const handleShowEmail = () => {
    setShowEmail(true);
  };
  return (
    <>
    <Helmet>
    <title>Betonoki - {t('contact')}</title>
    </Helmet>
      <Typography variant="h2" sx={{ marginBottom: '2rem' }}>
        {t('contact')}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
              height: '100%', 
              backgroundImage: 'url(/img/contact/bg_contact.jpg)', 
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Box sx={{ marginBottom: '0.5rem' }}>
              <img
                src="/img/logo.png"
                alt="Logo"
                style={{ width: '150px', height: 'auto' }}
              />
            </Box>

            <Box sx={{ marginBottom: '1rem' }}>
              <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>
                {t('designOffice')}
              </Typography>
            </Box>

            {/* Sekcja z dodatkowymi danymi firmy */}
            <Box sx={{ marginBottom: '1.5rem' }}>
              <SectionTitle>
                {t('invoiceData')}:
                </SectionTitle>
              <Typography sx={{mb:1}}>
                BETONOKI SP. Z O.O.
                </Typography>
                <ContactBox>
                    <PlaceOutlinedIcon
                        color="primary"
                        sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }}
                      />
                      <Typography sx={{mb:1}}> 
                      ul. Trauguta 37/4<br/>
                      44-120 Pyskowice
                  </Typography>
                </ContactBox>

                <ContactBox>
                <BusinessIcon
                  color="primary"
                  sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }}
                />

              <Typography>
                NIP: 9691667130<br/>
                KRS: 0001123324<br/>
                REGON: 529459192
              </Typography>
              </ContactBox>
            </Box>

            <Box sx={{ marginBottom: '1.5rem' }}>
              <SectionTitle>
                {t('officeAdress')}:
              </SectionTitle>
              <ContactBox>
                    <PlaceOutlinedIcon
                      color="primary"
                      sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }}
                    />
                  <Typography>
                    ul. Fabryczna 28 <br/>
                    42-600 Tarnowskie Góry
                  </Typography>
              </ContactBox>
            </Box>

            <Box  sx={{ marginBottom: '1.5rem' }}>
              <SectionTitle>
                {t('contactData')}:
              </SectionTitle>
              <ContactBox>
                <MailOutlineIcon sx={{ fontSize: "1.2rem", marginRight: "0.5rem" }} />
                {showEmail ? (
                  <a href={`mailto:${emailStart}${emailEnd}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ContactText>{`${emailStart}${emailEnd}`}</ContactText>
                  </a>
                ) : (
                  <ContactText onClick={handleShowEmail}>
                    {`${emailStart}@[${t`show`}]`}
                  </ContactText>
                )}
              </ContactBox>
                </Box>
              <Box >
              <SectionTitle>
                {t('socialLabel')}:
              </SectionTitle>

              
              <ContactBox
          component="a"
          href="https://www.linkedin.com/company/betonoki/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{textDecoration: 'none', color: 'inherit' }}
        >
          <LinkedInIcon sx={{ fontSize: "1.8rem", marginRight: "0.5rem" }} />
          <ContactText>linkedin.com/company/betonoki</ContactText>
        </ContactBox>
            </Box>
              {/* <Typography>
                <PhoneIcon
                  color="primary"
                  sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }}
                />
                <a href="tel:+48nr">[nr telefonu]</a>
              </Typography> */}
            </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Contact;
