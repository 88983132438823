import React from 'react';
import { Container, Typography } from '@mui/material';
import Services from '../components/offer/Services';
import Steps from '../components/offer/Steps';
import Sustainability from '../components/offer/Sustainability';
import Bim from '../components/offer/Bim';
import Virtual from '../components/offer/Virtual';
import HalfByHalf from '../components/global/HalfByHalf';
import { Directions } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
const Offer = () => {
  const { t } = useTranslation('offer');
  return (
    <>
    <Helmet>
      <title>Betonoki - {t('offer')}</title>
    </Helmet>
      <Typography variant="h2">
        {t('offer')}
      </Typography>

      <Steps/>

      <Services />

      <Sustainability />

      <HalfByHalf src={'/img/offer/bim/bim.jpg'} direction={'left'} alt='BIM'>
        <Typography variant="h5" sx={{ margin: '0 0 2rem 0' }}>
          {t('bimLabel')}
        </Typography> 
        <Typography paragraph>
          {t('bimDescription1')}
        </Typography>
        <Typography paragraph>
          {t('bimDescription2')}
        </Typography>
      </HalfByHalf>


      <HalfByHalf src={'/img/offer/virtual/virtual.jpg'} direction={'right'} alt='Virtual reality'>
        <Typography variant="h5" sx={{ margin: '0 0 2rem 0' }}>
          {t('vrLabel')}
        </Typography>
        <Typography paragraph>
          {t('vrDescriprion')}
        </Typography>
        <ul>
          <li>{t('vrList.3dModelsVisualizations')}</li>
          <li>{t('vrList.interiorDesignsVisualizations')}</li>
          <li>{t('vrList.marketingVisualizations')}</li>
          <li>{t('vrList.interactiveWalkthroughs')}</li>
          <li>{t('vrList.vrPresentation')}</li>
        </ul>
      </HalfByHalf>
    </>
  );
};

export default Offer;
