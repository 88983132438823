import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AnimatedSvg from '../components/notFound/AnimatedSvg';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Back = styled('button')(({ theme }) => ({
  marginTop: '2rem',
  background: 'none',
  color: '#000',
  border: 'none',
  padding: '0',
  fontWeight: 'bold',
  fontSize: '1.1rem',
  fontFamily: '"Oswald", sans-serif',
  display: 'inline-flex', 
  alignItems: 'center',
  cursor: 'pointer',
  textTransform: 'uppercase',
  '&:hover span': {
    transform: 'translateX(10px)',
  },
  '& span': {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '10px',
    transition: 'all 0.3s ',
  },
}));

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('notFound');

  return (
    <>
    <Helmet>
      <title>Betonoki - {t('page not fount')}</title>
    </Helmet>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        gap: 2,
        p: 0,
        margin: "0 auto"
      }}
    >

      <Typography variant="h3" component="h1" color="primary" gutterBottom>
        {t('notFoundHeader')}
      </Typography>
      <AnimatedSvg />
      
      <Typography paragraph>
        {t('youAreLost')}
      </Typography>
      

      <Link to="/offer">
                    <Back>
                        {t('back')}
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000">
                                <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/>
                            </svg>
                        </span>
                    </Back>
                </Link>
      
    </Box>
    </>
  );
};

export default NotFound;
